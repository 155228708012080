*{
  margin: 0;
  padding: 0;
}


/* Add your custom classes below */


/* :root {
  --primary-color: #007bff;
  --secondary-color: #6c757d;
  --success-color: #28a745;
  --info-color: #17a2b8;
  --warning-color: #ffc107;
  --danger-color: #dc3545;
  --light-color: #f8f9fa;
  --dark-color: #343a40;
} */

/* Bootstrap classes with custom colors */
/* .navbar-dark .navbar-nav .nav-link {
  color: var(--light-color);
}

.navbar-dark .navbar-nav .nav-link:hover {
  color: var(--dark-color);
}

.btn-primary {
  background-color: var(--primary-color);
  border-color: var(--primary-color);
}

.btn-primary:hover {
  background-color: var(--secondary-color);
  border-color: var(--secondary-color);
}

.btn-success {
  background-color: var(--success-color);
  border-color: var(--success-color);
}

.btn-info {
  background-color: var(--info-color);
  border-color: var(--info-color);
}

.btn-warning {
  background-color: var(--warning-color);
  border-color: var(--warning-color);
}

.btn-danger {
  background-color: var(--danger-color);
  border-color: var(--danger-color);
}

.btn-light {
  background-color: var(--light-color);
  border-color: var(--light-color);
}

.btn-dark {
  background-color: var(--dark-color);
  border-color: var(--dark-color);
}
 */
/* Add your custom classes below */
