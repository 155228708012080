/* .product-card {
    border-radius: 12px;
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
    transition: transform 0.3s ease, box-shadow 0.3s ease;
    overflow: hidden;
  }
  
  .product-card:hover {
    transform: scale(1.05);
    box-shadow: 0 12px 24px rgba(0, 0, 0, 0.3);
  }
  
  .card-img-top {
    height: 200px; 
    object-fit: cover;
  }
  
  .card-body {
    padding: 15px;
  }
  
  .card-title {
    font-size: 1.25rem;
    font-weight: bold;
  }
  
  .card-text {
    font-size: 1rem;
    color: #666;
  }
  
  .card-price {
    font-size: 1.2rem;
    font-weight: bold;
    color: #f12711; 
    margin: 10px 0;
  }
  
  .btn-show-more {
    display: block;
    width: 100%;
    text-align: center;
    margin-top: 10px;
  }
   */

   /* Card.css */
/* Card.css */
/* Card.css */
.product-card {
    border: 1px solid #ddd;
    border-radius: 8px;
    overflow: hidden;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s ease, box-shadow 0.3s ease;
    background-color: #fff;
  }
  
  .product-card:hover {
    transform: scale(1.05);
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
  }
  
  .card-img-top {
    transition: opacity 0.3s ease;
  }
  
  .product-card:hover .card-img-top {
    opacity: 0.8;
  }
  
  .card-body {
    padding: 15px;
  }
  
  .card-title {
    font-size: 1.25rem;
    font-weight: bold;
    margin-bottom: 0.5rem;
  }
  
  .card-text {
    color: #666;
    margin-bottom: 1rem;
  }
  
  .card-price {
    font-size: 1.5rem;
    font-weight: bold;
    color: #e74c3c;
    margin-bottom: 1rem;
  }
  
  .btn-show-more {
    transition: background-color 0.3s ease, transform 0.3s ease;
  }
  
  .product-card:hover .btn-show-more {
    background-color: #3498db;
    transform: scale(1.05);
  }
  
  .btn-show-more:focus {
    outline: none;
  }
  
  /* Add a subtle fade-in effect for the card */
  @keyframes fadeIn {
    from {
      opacity: 0;
      transform: translateY(10px);
    }
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }
  
  .product-card {
    animation: fadeIn 0.5s ease-out;
  }
  