/* .product-card {
    transform: scale(1);
    transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.product-card:hover {
    transform: scale(1.03);
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.2);
}

.card-img {
    height: 200px;
    object-fit: cover;
}

.card-title {
    font-size: 1.2rem;
    font-weight: bold;
    margin-bottom: 0.5rem;
}

.card-price {
    font-size: 1.1rem;
    color: #007bff;
    margin-bottom: 0.5rem;
}

.card-body {
    padding: 1rem;
}

.card-footer {
    background-color: #f8f9fa;
    border-top: 1px solid #dee2e6;
} */



/* .product-card {
this is right
    transform: scale(1);
    transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.product-card:hover {
    transform: scale(1.03);
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.2);
}

.card-img-left {
    width: 40%; 
    height: auto;
    object-fit: cover; 
}

.card-body {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 1rem;
    width: 60%; 
}

.card-title {
    font-size: 1.2rem;
    font-weight: bold;
    margin-bottom: 0.5rem;
}

.card-price {
    font-size: 1.1rem;
    color: #007bff;
    margin-bottom: 1rem;
}

.list-group-item {
    font-size: 0.9rem;
}

.card-footer {
    background-color: #f8f9fa;
    border-top: 1px solid #dee2e6;
} */

.product-card {
    transform: scale(1);
    transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.product-card:hover {
    transform: scale(1.03);
    box-shadow: 0 4px 12px rgb(2, 64, 97,2);
}

.card-img-left {
    width: 100%; /* Take full width of the image container */
    height: 80%; /* Fixed height to ensure images do not stretch */
    object-fit: cover; /* Ensure image covers its container without distortion */
}

.card-img-container {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    width: 50%;
    margin-top: 5rem;
    margin-bottom: 5rem;
}
.card-body {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 1rem;
    width: 50%; /* Take the remaining width of the card */
}

.card-title {
    font-size: 1.2rem;
    font-weight: bold;
    margin-bottom: 0.5rem;
}

.card-price {
    font-size: 1.1rem;
    color: #007bff;
    margin-bottom: 1rem;
}

.list-group-item {
    font-size: 0.9rem;
}

.card-footer {
    background-color: #f8f9fa;
    border-top: 1px solid #dee2e6;
}





